<script setup>
import { ref, defineAsyncComponent } from 'vue'

const laporanDetail = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/Detail.vue')
)
const laporanRangkuman = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/Rangkuman.vue')
)
const laporanPelanggan = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/PerPelanggan.vue')
)
const laporanSalesman = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/PerSalesman.vue')
)
const laporanMutasi = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/Mutasi.vue')
)
const laporanUmur = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/Umur.vue')
)
const laporanPembayaran = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/Pembayaran.vue')
)
const laporanPembayaranPelanggan = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/PembayaranPerPelanggan.vue')
)
const laporanPenjualan = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/Penjualan.vue')
)
const laporanKartu = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/Kartu.vue')
)
const laporanAreaSalesman = defineAsyncComponent(() =>
  import('@/components/laporan/piutang/PerAreaSalesman.vue')
)

let laporan = ref('detail')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Piutang Usaha Detail',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'detail'
    },
  },
  {
    label: 'Laporan Piutang Usaha Rangkuman',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'rangkuman'
    },
  },
  {
    label: 'Laporan Piutang Usaha Per Pelanggan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pelanggan'
    },
  },
  {
    label: 'Laporan Piutang Usaha Per Salesman',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'salesman'
    },
  },
  {
    label: 'Laporan Mutasi Piutang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'mutasi'
    },
  },
  {
    label: 'Laporan Umur Piutang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'umur'
    },
  },
  {
    label: 'Laporan Pembayaran Piutang Usaha',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pembayaran'
    },
  },
  {
    label: 'Laporan Pembayaran Piutang Usaha Per Pelanggan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pembayaranperpelanggan'
    },
  },
  {
    label: 'Laporan Penjualan vs Piutang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'penjualan'
    },
  },
  {
    label: 'Laporan Kartu Stok Piutang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'kartu'
    },
  },
  {
    label: 'Laporan Piutang Per Area Salesman',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'area-salesman'
    },
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Piutang Usaha</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Piutang / Usaha</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <laporanDetail v-if="laporan === 'detail'" @toggle="onToggle" />
          <laporanRangkuman v-if="laporan === 'rangkuman'" @toggle="onToggle" />
          <laporanPelanggan v-if="laporan === 'pelanggan'" @toggle="onToggle" />
          <laporanSalesman v-if="laporan === 'salesman'" @toggle="onToggle" />
          <laporanAreaSalesman v-if="laporan === 'area-salesman'" @toggle="onToggle" />
          <laporanMutasi v-if="laporan === 'mutasi'" @toggle="onToggle" />
          <laporanUmur v-if="laporan === 'umur'" @toggle="onToggle" />
          <laporanPembayaran
            v-if="laporan === 'pembayaran'"
            @toggle="onToggle"
          />
          <laporanPembayaranPelanggan
            v-if="laporan === 'pembayaranperpelanggan'"
            @toggle="onToggle"
          />
          <laporanPenjualan v-if="laporan === 'penjualan'" @toggle="onToggle" />
          <laporanKartu v-if="laporan === 'kartu'" @toggle="onToggle" />
        </div>
      </div>
    </div>
  </div>
</template>
