<script setup>
import { ref, defineAsyncComponent } from 'vue'

const laporanKartu = defineAsyncComponent(() =>
  import('@/components/laporan/persediaan/Kartu.vue')
)

const laporanMutasi = defineAsyncComponent(() =>
  import('@/components/laporan/persediaan/Mutasi.vue')
)

const laporanOpname = defineAsyncComponent(() =>
  import('@/components/laporan/persediaan/Opname.vue')
)

const laporanSlow = defineAsyncComponent(() =>
  import('@/components/laporan/persediaan/Slow.vue')
)

const laporanBest = defineAsyncComponent(() =>
  import('@/components/laporan/persediaan/Best.vue')
)

const laporanExpired = defineAsyncComponent(() =>
  import('@/components/laporan/persediaan/Expired.vue')
)

const laporanGolongan = defineAsyncComponent(() =>
  import('@/components/laporan/persediaan/Golongan.vue')
)

const laporanGlobal = defineAsyncComponent(() =>
  import('@/components/laporan/persediaan/Global.vue')
)

const laporanDaftar = defineAsyncComponent(() =>
  import('@/components/laporan/persediaan/Daftar.vue')
)

let laporan = ref('kartu')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Kartu Stok',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'kartu'
    },
  },
  {
    label: 'Laporan Mutasi Stok',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'mutasi'
    },
  },
  {
    label: 'Laporan Stock Opname',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'opname'
    },
  },
  {
    label: 'Laporan Barang Slow Moving',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'slow'
    },
  },
  {
    label: 'Laporan Barang Best Seller',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'best'
    },
  },
  {
    label: 'Laporan Daftar Obat Expired',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'expired'
    },
  },
  {
    label: 'Laporan Daftar Obat Per Golongan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'golongan'
    },
  },
  {
    label: 'Laporan Stok Global',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'global'
    },
  },
  {
    label: 'Laporan Daftar Barang Per Pabrik',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'daftar'
    },
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Persediaan Barang</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Persediaan / Barang</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <laporanKartu v-if="laporan === 'kartu'" @toggle="onToggle" />
          <laporanMutasi v-if="laporan === 'mutasi'" @toggle="onToggle" />
          <laporanOpname v-if="laporan === 'opname'" @toggle="onToggle" />
          <laporanSlow v-if="laporan === 'slow'" @toggle="onToggle" />
          <laporanBest v-if="laporan === 'best'" @toggle="onToggle" />
          <laporanExpired v-if="laporan === 'expired'" @toggle="onToggle" />
          <laporanGolongan v-if="laporan === 'golongan'" @toggle="onToggle" />
          <laporanGlobal v-if="laporan === 'global'" @toggle="onToggle" />
          <laporanDaftar v-if="laporan === 'daftar'" @toggle="onToggle" />
        </div>
      </div>
    </div>
  </div>
</template>
