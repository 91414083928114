<script setup>
import { ref, defineAsyncComponent } from 'vue'

const laporanPenjualanGolongan = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/PenjualanGolongan.vue')
)

const laporanReturGolongan = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/ReturGolongan.vue')
)

const laporanTriwulanBatch = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/TriwulanBatch.vue')
)

const laporanTriwulanBarang = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/TriwulanBarang.vue')
)

const laporanEReport = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/EReport.vue')
)

const laporanSiOdie = defineAsyncComponent(() =>
  import('@/components/laporan/apoteker/SiOdie.vue')
)

let laporan = ref('PenjualanGolongan')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Penjualan Barang per Golongan Obat',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'PenjualanGolongan'
    },
  },
  {
    label: 'Laporan Retur Penjualan per Golongan Obat',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'ReturGolongan'
    },
  },
  {
    label: 'Laporan Triwulan per Barang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'TriwulanBarang'
    },
  },
  {
    label: 'Laporan E-Report',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'EReport'
    },
  },
  {
    label: 'Laporan SI-ODIE',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'SiOdie'
    },
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Laporan Apoteker</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Laporan / Apoteker</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <laporanPenjualanGolongan
            v-if="laporan === 'PenjualanGolongan'"
            @toggle="onToggle"
          />
          <laporanReturGolongan
            v-if="laporan === 'ReturGolongan'"
            @toggle="onToggle"
          />
          <laporanTriwulanBatch
            v-if="laporan === 'TriwulanBatch'"
            @toggle="onToggle"
          />
          <laporanTriwulanBarang
            v-if="laporan === 'TriwulanBarang'"
            @toggle="onToggle"
          />
          <laporanEReport v-if="laporan === 'EReport'" @toggle="onToggle" />
          <laporanSiOdie v-if="laporan === 'SiOdie'" @toggle="onToggle" />
        </div>
      </div>
    </div>
  </div>
</template>
