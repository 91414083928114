<script setup>
import { ref, defineAsyncComponent } from 'vue'

const laporanPelanggan = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/Pelanggan.vue')
)
const laporanSalesman = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/Salesman.vue')
)
const laporanArea = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/Area.vue')
)
const laporanPabrik = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/Pabrik.vue')
)
const laporanGolongan = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/Golongan.vue')
)
const laporanBarang = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/Barang.vue')
)
const laporanRetur = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/Retur.vue')
)
const laporanDetail = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/Detail.vue')
)
const laporanPelangganBarang = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/PelangganBarang.vue')
)
const laporanPelangganPabrik = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/PelangganPabrik.vue')
)
const laporanBarangPabrik = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/BarangPabrik.vue')
)
const laporanItemBarang = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/ItemBarang.vue')
)
const laporanSalesmanPabrik = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/SalesmanPabrik.vue')
)
const laporanDaftarPenjualan = defineAsyncComponent(() =>
  import('@/components/laporan/penjualan/DaftarPenjualan.vue')
)

let laporan = ref('pelanggan')
let hideSideBar = ref(false)

const items = ref([
  {
    label: 'Laporan Penjualan Per Pelanggan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pelanggan'
    },
  },
  {
    label: 'Laporan Penjualan Per Salesman',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'salesman'
    },
  },
  {
    label: 'Laporan Penjualan Per Area Salesman',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'area'
    },
  },
  {
    label: 'Laporan Penjualan Per Pabrik',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pabrik'
    },
  },
  {
    label: 'Laporan Penjualan Per Golongan Barang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'golongan'
    },
  },
  {
    label: 'Laporan Penjualan Per Barang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'barang'
    },
  },
  {
    label: 'Laporan Retur Penjualan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'retur'
    },
  },
  {
    label: 'Laporan Daftar Penjualan',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'daftar-penjualan'
    },
  },
  {
    label: 'Laporan Penjualan Detail',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'detail'
    },
  },
  {
    label: 'Laporan Penjualan Per Pelanggan Per Barang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pelanggan-barang'
    },
  },
  {
    label: 'Laporan Penjualan Per Pelanggan Per Pabrik',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'pelanggan-pabrik'
    },
  },
  {
    label: 'Laporan Rekapitulasi Penjualan Barang Per Pabrik',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'barang-pabrik'
    },
  },
  {
    label: 'Laporan Penjualan Per Item Barang',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'item'
    },
  },
  {
    label: 'Laporan Penjualan Per Salesman Per Pabrik',
    icon: 'pi pi-arrow-circle-right',
    command: () => {
      laporan.value = 'salesman-pabrik'
    },
  },
])

function onToggle() {
  hideSideBar.value = !hideSideBar.value
}
</script>

<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Laporan Penjualan</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Laporan / Penjualan</span>
      </div>
    </div>
    <div class="grid">
      <div
        class="col-12 md:col-3"
        :class="{
          hidden: hideSideBar,
          'transition-duration-500': hideSideBar,
        }"
      >
        <Menu :model="items" class="menu-laporan w-full border-none" />
      </div>
      <div
        class="card col-12 md:col-9"
        :class="{
          'md:col-12': hideSideBar,
          'transition-duration-200': hideSideBar,
        }"
      >
        <div>
          <laporanPelanggan v-if="laporan === 'pelanggan'" @toggle="onToggle" />
          <laporanSalesman v-if="laporan === 'salesman'" @toggle="onToggle" />
          <laporanArea v-if="laporan === 'area'" @toggle="onToggle" />
          <laporanPabrik v-if="laporan === 'pabrik'" @toggle="onToggle" />
          <laporanGolongan v-if="laporan === 'golongan'" @toggle="onToggle" />
          <laporanBarang v-if="laporan === 'barang'" @toggle="onToggle" />
          <laporanRetur v-if="laporan === 'retur'" @toggle="onToggle" />
          <laporanDaftarPenjualan v-if="laporan === 'daftar-penjualan'" @toggle="onToggle" />
          <laporanDetail v-if="laporan === 'detail'" @toggle="onToggle" />
          <laporanPelangganBarang
            v-if="laporan === 'pelanggan-barang'"
            @toggle="onToggle"
          />
          <laporanPelangganPabrik
            v-if="laporan === 'pelanggan-pabrik'"
            @toggle="onToggle"
          />
          <laporanBarangPabrik
            v-if="laporan === 'barang-pabrik'"
            @toggle="onToggle"
          />
          <laporanItemBarang v-if="laporan === 'item'" @toggle="onToggle" />
          <laporanSalesmanPabrik
            v-if="laporan === 'salesman-pabrik'"
            @toggle="onToggle"
          />
        </div>
      </div>
    </div>
  </div>
</template>
